import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import AudioApi from '@phoenix7dev/audio-api';
import { Button } from '@phoenix7dev/shared-components';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { eventManager } from '../../slotMachine/config';
import { isMobileDevice } from '../../utils';

import AutoPlay from './AutoPlay';
import ButtonBehaviors from './ButtonBehaviors';
import Features from './Features';
import GameRules from './GameRules';
import PayLines from './PayLines';
import PaytableComponent from './Paytable';
import styles from './info.module.scss';

export const InfoIcon: React.FC<{
  setType: React.Dispatch<React.SetStateAction<'menu' | 'info' | 'settings' | 'history'>>;
}> = ({ setType }) => (
  <Button
    intent="info"
    onClick={() => {
      setType('info');
      AudioApi.stop({ type: ISongs.SFX_UI_Close });
      AudioApi.play({ type: ISongs.SFX_UI_MenuOpen });
      eventManager.emit(EventTypes.TOGGLE_UI_MENU);
    }}
  />
);

const Paytable: React.FC<{
  setType: React.Dispatch<React.SetStateAction<'menu' | 'info' | 'settings' | 'history'>>;
  show: boolean;
}> = ({ setType, show }) => {
  const { t } = useTranslation();

  const handleClosePopup = (): void => {
    setTimeout(() => {
      if (!AudioApi.isPlaying(ISongs.SFX_UI_MenuOpen)) {
        AudioApi.play({ type: ISongs.SFX_UI_Close });
        eventManager.emit(EventTypes.TOGGLE_UI_MENU);
      }
    }, 0);
    setType('menu');
  };

  const handleClickTab = (): void => {
    AudioApi.play({ type: ISongs.SFX_UI_General });
    eventManager.emit(EventTypes.TOGGLE_UI_MENU);
  };

  const handleHoverTab = (): void => {
    if (!isMobileDevice()) {
      AudioApi.play({ type: ISongs.SFX_UI_Hover });
    }
  };
  return (
    <div style={{ display: show ? 'flex' : 'none' }}>
      <Button intent="close" className={styles['close-btn']!} onClick={handleClosePopup} />
      <Tabs
        className={styles['info']}
        domRef={(elem) => {
          elem?.querySelector(':scope > div')?.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        }}
      >
        <TabList className={styles['header']}>
          <Tab className={styles['item']} onClick={handleClickTab} onMouseOver={handleHoverTab}>
            {t('infoTabPayTable')}
          </Tab>
          <Tab className={styles['item']} onClick={handleClickTab} onMouseOver={handleHoverTab}>
            {t('infoTabFeatures')}
          </Tab>
          <Tab className={styles['item']} onClick={handleClickTab} onMouseOver={handleHoverTab}>
            {t('infoTabGameRules')}
          </Tab>
          <Tab className={styles['item']} onClick={handleClickTab} onMouseOver={handleHoverTab}>
            {t('infoTabPayLines')}
          </Tab>
          <Tab className={styles['item']} onClick={handleClickTab} onMouseOver={handleHoverTab}>
            {t('infoTabButtonBehaviors')}
          </Tab>
          <Tab className={styles['item']} onClick={handleClickTab} onMouseOver={handleHoverTab}>
            {t('infoTabAutoPlay')}
          </Tab>
        </TabList>
        <div className={styles['container']}>
          <TabPanel>
            <PaytableComponent />
          </TabPanel>
          <TabPanel>
            <Features />
          </TabPanel>
          <TabPanel>
            <GameRules />
          </TabPanel>
          <TabPanel>
            <PayLines />
          </TabPanel>
          <TabPanel>
            <ButtonBehaviors />
          </TabPanel>
          <TabPanel>
            <AutoPlay />
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default Paytable;
