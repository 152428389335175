import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AudioApi from '@phoenix7dev/audio-api';
import { Button, Popup } from '@phoenix7dev/shared-components/';

import { ISongs } from '../../config';
import { EventTypes, ModalOpeningTypes } from '../../global.d';
import {
  setAlbumListPosition,
  setIsModalOpeningInProgress,
  setIsOpenAlbumInfo,
  setIsOpenAlbumModal,
} from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import { eventManager } from '../../slotMachine/config';

import CharaTable from '.';

const AlbumMenu: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation();
  const { data: configData } = useQuery<IConfig>(configGql);
  const { isOpenAlbumModal } = configData!;

  useEffect(() => {
    if (isOpenAlbumModal) {
      AudioApi.stop({ type: ISongs.SFX_UI_Close });
      AudioApi.play({ type: ISongs.SFX_UI_MenuOpen });
    }
    setShowPopup(isOpenAlbumModal);
  }, [isOpenAlbumModal]);

  useEffect(() => {
    if (showPopup && configData?.isSpinInProgress) {
      setShowPopup(false);
      setIsOpenAlbumModal(false);
    }
  }, [configData?.isSpinInProgress, showPopup]);

  const handleClose = (directClickToCloseIcon = false) => {
    if (
      setIsModalOpeningInProgress() &&
      setIsModalOpeningInProgress() !== ModalOpeningTypes.ALBUM_MENU &&
      !directClickToCloseIcon
    ) {
      return;
    }

    if (directClickToCloseIcon && setIsOpenAlbumInfo()) {
      setIsOpenAlbumInfo(false);
      AudioApi.play({ type: ISongs.SFX_UI_Close });
      return;
    }

    if (!setIsOpenAlbumInfo()) {
      const divElement = document.getElementById('album-list-id');
      setAlbumListPosition(divElement!.scrollTop);
    }

    setTimeout(() => {
      if (!AudioApi.isPlaying(ISongs.SFX_UI_MenuOpen)) {
        AudioApi.play({ type: ISongs.SFX_UI_Close });
        if (directClickToCloseIcon) {
          eventManager.emit(EventTypes.TOGGLE_UI_MENU);
        }
      }
      setIsOpenAlbumInfo(false);
    }, 0);
    setIsOpenAlbumModal(false);
  };

  return (
    <Popup
      id="popup"
      className={setIsOpenAlbumInfo() ? 'popup3' : 'popup'}
      showNow={showPopup}
      setShowNow={() => handleClose()}
    >
      <div className={setIsOpenAlbumInfo() ? 'popup3__title' : 'popup__title'}>{t('albumBtn')}</div>
      <Button
        intent="close"
        className={setIsOpenAlbumInfo() ? 'popup3__close' : 'popup__close'}
        onClick={() => handleClose(true)}
      />
      <CharaTable />
    </Popup>
  );
};

export default AlbumMenu;
