import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import {
  setBrokenGame,
  setFreeRoundsBonus,
  setIsAutoSpins,
  setIsLeftHandMode,
  setIsModalOpeningInProgress,
  setIsOpenAlbumInfo,
  setIsOpenAlbumModal,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
} from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { isMobileDevice, updateTextScale } from '../../utils';
import { isFreeSpinsMode } from '../../utils/helper';
import { BACKGROUND_SIZE_HEIGHT, BACKGROUND_SIZE_WIDTH, BgSkin } from '../background/config';
import {
  FEATURE_BTN_LANDSCAPE_HEIGHT,
  FEATURE_BTN_LANDSCAPE_WIDTH,
  FEATURE_BTN_PORTRAIT_HEIGHT,
  FEATURE_BTN_PORTRAIT_WIDTH,
  FEATURE_BTN_TEXT_PRESS_X_OFFSET,
  FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
  FEATURE_BTN_TEXT_X_OFFSET,
  FEATURE_BTN_TEXT_Y_OFFSET,
  REEL_HEIGHT,
  SAFE_AREA_LANDSCAPE_HEIGHT,
  SAFE_AREA_LANDSCAPE_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
  eventManager,
} from '../config';

class BuyFeatureBtn extends PIXI.Container {
  private btn: PIXI.Sprite;

  private text: PIXI.Text;

  isDisabled: boolean;

  isLeftHandMode: boolean;

  private windowWidth: number;

  private windowHeight: number;

  private isSceneChange: boolean;

  constructor() {
    super();
    this.setPosition(SAFE_AREA_LANDSCAPE_WIDTH, SAFE_AREA_LANDSCAPE_HEIGHT);
    this.isDisabled = false;
    this.isSceneChange = false;
    this.visible = !setBrokenGame();
    this.btn = this.initBuyFeatureBtn();
    this.isLeftHandMode = setIsLeftHandMode();
    this.addChild(this.btn);

    this.text = this.initBuyFeatureText();
    this.addChild(this.text);

    this.windowWidth = BACKGROUND_SIZE_WIDTH;
    this.windowHeight = BACKGROUND_SIZE_HEIGHT;

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME, (isVisible: boolean) => (this.visible = !isVisible));

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, this.handleLeftHandMode.bind(this));

    eventManager.on(EventTypes.SET_SCENE_CHANGE, (isSceneChange: boolean) => {
      this.isSceneChange = isSceneChange;
      this.handleDisable(isSceneChange);
    });
    eventManager.addListener(EventTypes.SET_BROKEN_BUY_FEATURE, (isBroken: boolean) => {
      this.handleDisable(isBroken);
    });
  }

  private initBuyFeatureBtn() {
    const btn = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureBtn));
    btn.width = FEATURE_BTN_LANDSCAPE_WIDTH;
    btn.height = FEATURE_BTN_LANDSCAPE_HEIGHT;
    btn.x = 0;
    btn.y = 0;
    btn.interactive = true;
    btn.buttonMode = true;
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', () => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnHover);
        this.text.style = buyFeatureHoverTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.text.position.set(
          this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET,
          this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
        );
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.style = buyFeatureTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.text.position.set(
          this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET,
          this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
        );
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnPressed);
        this.text.style = buyFeatureClickTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.text.position.set(
          this.width / 2 + FEATURE_BTN_TEXT_PRESS_X_OFFSET,
          this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
        );
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
        this.text.style = buyFeatureTextStyle;
        this.text.anchor.set(0.5, 0.5);
        this.text.position.set(
          this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET,
          this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
        );
      }
    });
    return btn;
  }

  private initBuyFeatureText(): PIXI.Text {
    const text = new PIXI.Text(i18n.t<string>('buyFeatureBtn'), buyFeatureTextStyle);
    text.resolution = 1;
    text.anchor.set(0.5, 0.5);
    text.position.set(this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET, this.height / 2 + FEATURE_BTN_TEXT_Y_OFFSET);
    updateTextScale(text, isMobileDevice() ? 120 : 120, isMobileDevice() ? 150 : 100);

    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsModalOpeningInProgress(ModalOpeningTypes.BUY_FEATURE_POPUP);
      setIsOpenAutoplayModal(false);
      setIsOpenBetSettingsModal(false);
      setIsOpenMenuModal({ isOpen: false, type: 'menu' });
      setIsOpenAlbumModal(false);
      setIsOpenAlbumInfo(false);
      AudioApi.play({ type: ISongs.MultiUp_1 });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP, true);
      eventManager.emit(EventTypes.POPUP_BG_VISIBLE, true);

      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if ((this.isSceneChange && !disable) || this.isFreeRoundBonus) return;
    updateTextScale(
      this.text,
      this.windowWidth > this.windowHeight ? 120 : 150,
      this.windowWidth > this.windowHeight ? 150 : 180,
      2,
    );

    if (this.isAutoSpinInProgress) {
      this.btn.buttonMode = false;
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnNotActive);
      if (this.windowWidth > this.windowHeight) {
        this.text.style = buyFeatureDisableTextStyle;
      } else {
        this.text.style = buyFeatureDisableTextStyle;
      }
      this.text.position.set(
        this.width / 2 + FEATURE_BTN_TEXT_PRESS_X_OFFSET,
        this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
      );

      return;
    }

    this.isDisabled = disable;

    if (disable) {
      this.btn.buttonMode = false;
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtnNotActive);
      if (this.windowWidth > this.windowHeight) {
        this.text.style = buyFeatureDisableTextStyle;
      } else {
        this.text.style = buyFeatureDisableTextStyle;
      }
      this.text.position.set(
        this.width / 2 + FEATURE_BTN_TEXT_PRESS_X_OFFSET,
        this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
      );
    } else {
      this.btn.buttonMode = true;
      this.btn.texture = PIXI.Texture.from(ResourceTypes.buyFeatureBtn);
      this.text.style = buyFeatureTextStyle;
      this.text.position.set(
        this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET,
        this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
      );
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  private get isFreeRoundBonus(): boolean {
    return this.isDisabled && setFreeRoundsBonus().isActive;
  }

  private resize(width: number, height: number): void {
    this.windowWidth = width;
    this.windowHeight = height;

    if (width > height) {
      this.btn.width = FEATURE_BTN_LANDSCAPE_WIDTH;
      this.btn.height = FEATURE_BTN_LANDSCAPE_HEIGHT;
    } else {
      this.btn.width = FEATURE_BTN_PORTRAIT_WIDTH;
      this.btn.height = FEATURE_BTN_PORTRAIT_HEIGHT;
    }

    this.setPosition(width, height);
    this.text.position.set(
      this.width / 2 + FEATURE_BTN_TEXT_X_OFFSET,
      this.height / 2 + FEATURE_BTN_TEXT_PRESS_Y_OFFSET,
    );

    this.handleDisable(this.isDisabled);
  }

  private setPosition(width: number, height: number): void {
    if (width > height) {
      if (setIsLeftHandMode() && isMobileDevice()) {
        this.x = 1350;
      } else {
        this.x = -54 - FEATURE_BTN_LANDSCAPE_WIDTH;
      }
      this.y = REEL_HEIGHT + 10;
    } else {
      if (setIsLeftHandMode() && isMobileDevice()) {
        this.x = 920;
      } else {
        this.x = 0;
      }
      this.y = SLOTS_CONTAINER_HEIGHT + FEATURE_BTN_LANDSCAPE_HEIGHT / 4;
    }
  }

  private handleLeftHandMode = (_isLhm: boolean): void => {
    this.setPosition(this.windowWidth, this.windowHeight);
  };

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {
    if (isFreeSpinsMode(settings.mode)) {
      this.visible = false;
    } else {
      this.visible = true;
    }
  }
}

export default BuyFeatureBtn;
